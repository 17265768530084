const viewportSizeMax = [992, 500];

const viewportSizeMin = [0, 0];

const slotInpageMax = [
  [250, 250],
  [300, 250],
  [300, 50],
  [320, 50],
  [320, 100],
  [336, 250],
  [336, 280],
  [640, 360],
  [468, 80],
  [728, 90],
  [750, 200],
  [970, 50],
  [970, 250],
  [970, 90],
  [970, 280],
  "fluid",
];

const slotInpageMin = [
  [250, 250],
  [300, 250],
  [300, 50],
  [320, 50],
  [320, 100],
  [336, 280],
  [336, 250],
  "fluid",
];

const adUnitPath1 = "/22830760534,22962986844/fastmotorcity/inicio/top";
const size1 = [
  [320, 100],
  [920, 100],
  [300, 50],
  [300, 100],
  [728, 90],
  [970, 90],
];

const adUnitPath2 = "/22830760534,22962986844/fastmotorcity/inicio/inpage1";
const size2 = [
  [300, 450],
  [336, 280],
  [320, 100],
  [320, 450],
  [920, 100],
  "fluid",
  [300, 50],
  [300, 250],
  [728, 90],
  [920, 250],
  [970, 250],
  [970, 90],
];

const adUnitPath3 = "/22830760534,22962986844/fastmotorcity/inicio/inpage2";
const size3 = [
  [300, 250],
  [640, 360],
  [300, 50],
  [728, 90],
  [320, 50],
  [468, 80],
  "fluid",
  [250, 250],
  [320, 450],
  [336, 250],
  [970, 250],
  [970, 50],
  [320, 100],
  [640, 480],
  [300, 450],
  [336, 280],
];

const adUnitPath4 = "/22830760534,22962986844/fastmotorcity/inicio/inpage3";
const size4 = [
  [300, 250],
  [640, 360],
  [300, 50],
  [728, 90],
  [320, 50],
  [468, 80],
  "fluid",
  [250, 250],
  [320, 450],
  [336, 250],
  [970, 250],
  [970, 50],
  [320, 100],
  [640, 480],
  [300, 450],
  [336, 280],
];

const adUnitPathAnchor = "/22830760534,22962986844/fastmotorcity/inicio/anchor";

interface AdConfig {
  path: string;
  size: (string | number[])[];
  divName: string;
  customMaxViewportSize?: number[];
  customMaxSlotSize?: (string | number[])[];
  customMinViewportSize?: number[];
  customMinSlotSize?: (string | number[])[];
}

type AdGoatConfig<T extends string> = {
  [key in T]: AdConfig;
} & {
  maxViewportSize: number[];
  maxSlotSize: (string | number[])[];
  minViewportSize: number[];
  minSlotSize: (string | number[])[];
  anchorPath: string;
};

type AdNames = "firstAd" | "secondAd" | "thirdAd" | "fourthdAd";

const adGoatConfig: AdGoatConfig<AdNames> = {
  firstAd: {
    path: adUnitPath1,
    size: size1,
    divName: "fluid-1",
    customMaxViewportSize: [992, 0],
    customMaxSlotSize: [
      [960, 100],
      [728, 90],
    ],
    customMinViewportSize: [0, 0],
    customMinSlotSize: [
      [320, 50],
      [300, 100],
      [320, 100],
    ],
  },
  secondAd: {
    path: adUnitPath2,
    size: size2,
    divName: "fluid-2",
  },
  thirdAd: {
    path: adUnitPath3,
    size: size3,
    divName: "fluid-3",
  },
  fourthdAd: {
    path: adUnitPath4,
    size: size4,
    divName: "fluid-4",
  },
  maxViewportSize: viewportSizeMax,
  maxSlotSize: slotInpageMax,
  minViewportSize: viewportSizeMin,
  minSlotSize: slotInpageMin,
  anchorPath: adUnitPathAnchor,
};

export default adGoatConfig;
