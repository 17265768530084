<template>
  <div>
    <Toast
      position="top-right"
      :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }"
    />
    <section id="home" class="menubar-container d-flex">
      <div class="menu-container">
        <Menubar :model="items">
          <template #start>
            <img
              width="140"
              class="logo-default"
              src="./assets/images/logo.png"
              :alt="name"
              @click="redirect"
            />
          </template>
        </Menubar>
      </div>
    </section>
    <router-view class="view" />
    <footer class="main-footer pt-100">
      <section>
        <div class="container">
          <div class="row d-md-flex flex-wrap align-items-center mb-4">
            <div class="lqd-column col-md-4 col-md-offset-1 col-sm-12">
              <ul class="social-icon social-icon-md mb-25">
                <li>
                  <a :href="fbPage" target="_blank">
                    <i class="pi pi-facebook"></i>/{{ name }}
                  </a>
                </li>
              </ul>
            </div>

            <div class="lqd-column col-md-2 col-md-offset-2 col-xs-12">
              <h3 class="widget-title">Navegación</h3>
              <ul class="lqd-custom-menu reset-ul font-size-16 lh-2">
                <li><a href="/#">Home</a></li>
                <li><a href="/#work">Cómo funciona</a></li>
                <li><a href="/#benefits">Beneficios</a></li>
              </ul>
            </div>

            <div class="lqd-column col-md-3 col-xs-12">
              <h3 class="widget-title">Acerca de Nosotros</h3>
              <ul class="lqd-custom-menu reset-ul font-size-16 lh-2">
                <li>
                  <a href="/terminos-y-condiciones">Términos y condiciones</a>
                </li>
                <li><a href="/aviso-de-privacidad">Aviso de Privacidad</a></li>
                <li>&emsp;</li>
              </ul>
            </div>
          </div>

          <div class="row text-center font-size-16 mb-5">
            <div class="col-xs-12">
              Nuestra oficina registrada: Av. Paseo de las Palmas 735, Lomas de
              Chapultepec I Secc, Miguel Hidalgo, 11000 Ciudad de México, CDMX.
              <br />
              {{ new Date().getUTCFullYear() }} ©
              <span class="text-secondary font-weight-bold">
                {{ name }}
              </span>
              - Todos los derechos reservados. Al usar este sitio, aceptas
              nuestra política de privacidad, así como nuestros términos y
              condiciones.
            </div>
          </div>
        </div>
      </section>
    </footer>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
export default {
  components: {
    Menubar: Menubar,
    Toast: Toast,
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
      fbPage: process.env.VUE_APP_FB_PAGE,
      items: [
        {
          label: "HOME",
          to: "/#home",
        },
        {
          label: "CÓMO FUNCIONA",
          to: "/#work",
        },
        {
          label: "BENEFICIOS",
          to: "/#benefits",
        },
      ],
    };
  },
  setup() {
    const toast = useToast();
    const year = new Date().getUTCFullYear();
    return {
      toast,
      year,
    };
  },
  methods: {
    redirect() {
      if (
        this.$route.path === "/registro" ||
        this.$route.path === "/cuestionario"
      ) {
        this.$router.push("/resultados");
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style>
.list-footer {
  list-style-type: none;
  text-align: left;
}

.list {
  color: #058fcc;
  font-size: 12px;
}

.p {
  color: white;
  font-size: 14px;
  margin: 1em;
}

.bg-black {
  background-color: #000;
}

i {
  font-size: 19px;
}

.view {
  width: 100%;
}
.menu-container {
  width: 100%;
  position: relative;
}
.p-menubar {
  z-index: 100;
  width: 100%;
  top: 0;
  background: black !important;
  border: transparent !important;
  margin-top: 30px;
}

.p-menubar .p-menubar-button {
  position: absolute;
  line-height: 50px;
  left: 90%;
  top: 0;
}

.p-menubar .p-menubar-button:hover {
  font-size: 2rem;
  background: black !important;
  color: #fff !important;
  outline: 10px solid black !important;
}

.p-menubar .p-menubar-button .pi {
  font-size: 2rem;
  background: black !important;
  color: #fff !important;
}

.p-menubar .p-menubar-root-list {
  position: absolute;
  right: 0px;
  background: black !important;
  border: 1px solid black !important;
  width: 45%;
}

.p-menubar .p-menubar-root-list :hover {
  background: black !important;
  border: 1px solid black !important;
  text-decoration: underline 6px solid #ffb09f;
}

.p-menubar .p-menubar-root-list :focus {
  background: black !important;
  border: 1px solid black !important;
  text-decoration: underline 6px solid #ffb09f;
}

.p-menuitem-text {
  color: #ffffff !important;
  font-family: "Glacial Indifference", sans-serif !important;
}

.p-menuitem-text-menubar {
  color: #ffffff !important;
  font-family: "Glacial Indifference", sans-serif !important;
}

@media (min-width: 1825px) {
  .footer {
    padding: 0 25rem;
  }
}

.p-fb {
  margin-top: 0;
}

.p-error {
  color: #ffb09f !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--color-secondary) !important;
}

.p-success {
  color: var(--color-secondary) !important;
}

.p-dropdown .p-dropdown-label {
  margin-top: -5px;
}

.social-icon i {
  color: var(--color-link);
}
</style>
